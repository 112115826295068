<template>
    <dashboard-layout>
        <v-row v-if="user && user.patient">
            <v-col cols="12">
                <v-card class="mx-auto my-auto" rounded="lg">
                    <v-card-title>{{$t('scoliotracker.donate')}}</v-card-title>
                    <v-card-text>
                        <v-row>
                            <iframe id='kofiframe' src='https://ko-fi.com/scoliotracker/?hidefeed=true&widget=true&embed=true&preview=true' style='border:none;width:100%;padding:4px;background:#f9f9f9;' height='712' title='scoliotracker'></iframe>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </dashboard-layout>
</template>

<script>
import DashboardLayout from "../../../layouts/dashboard/Index";

export default {
    name: "Profile",
    components: { DashboardLayout },
    data: function () {
        return {
            user: {},
        };
    },
    methods: {
    },
    mounted() {
        this.user = this.$auth.user();
    },
};
</script>

<style scoped>

</style>
